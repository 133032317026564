import React, { useEffect, useState } from 'react';  
import AWS from 'aws-sdk';  
import { s3ReadConfig } from '../../s3-read-config';  
import ImageModal from '../ImageModal/ImageModal';  
import ImageViewer from '../ImageViewer/ImageViewer';  
import VideoModal from '../VideoModal/VideoModal';  
import PlayCircleFilledOutlinedIcon from '@mui/icons-material/PlayCircleFilledOutlined';  
import ReactPlayer from 'react-player';  
import {  
  Container,  
  GalleryContainer,  
  GalleryItem,  
  GalleryImage,  
  VideoIconOverlay,  
} from './VideoGallery.styles';  
  
interface MediaItem {  
  original: string;  
  thumbnail: string;  
  key: string;  
  isVideo: boolean;  
}  
  
const VideoGallery: React.FC = () => {  
  const [mediaItems, setMediaItems] = useState<MediaItem[]>([]);  
  const [isImageModalOpen, setIsImageModalOpen] = useState(false);  
  const [isVideoModalOpen, setIsVideoModalOpen] = useState(false);  
  // const [currentMediaIndex, setCurrentMediaIndex] = useState(0);  
  const currentMediaIndex = 0;  
  const [currentVideoUrl, setCurrentVideoUrl] = useState('');  
  const [isImageViewerOpen, setIsImageViewerOpen] = useState(false);  
  const [currentImageSrc, setCurrentImageSrc] = useState('');  
  
  useEffect(() => {  
    const fetchMediaItems = async () => {  
      const s3 = new AWS.S3({  
        region: s3ReadConfig.region,  
        accessKeyId: s3ReadConfig.accessKeyId,  
        secretAccessKey: s3ReadConfig.secretAccessKey,  
        endpoint: s3ReadConfig.endpoint,  
        s3ForcePathStyle: true, // Necessary for custom endpoints  
      });  
  
      const params = {  
        Bucket: s3ReadConfig.bucketName,  
      };  
  
      try {  
        const data = await s3.listObjectsV2(params).promise();  
        const mediaUrls =  
          data.Contents?.map((item) => {  
            const url = s3.getSignedUrl('getObject', {  
              Bucket: s3ReadConfig.bucketName,  
              Key: item.Key,  
            });  
            return {  
              original: url,  
              thumbnail: url,  
              key: item.Key || '',  
              isVideo:  
                item.Key?.toLowerCase().endsWith('.mp4') ||  
                item.Key?.toLowerCase().endsWith('.webm') ||  
                item.Key?.toLowerCase().endsWith('.mov') ||  
                false, // Ensure isVideo is always boolean  
            };  
          }) || [];  
        const videoUrls = mediaUrls.filter(item => item.isVideo);  
        setMediaItems(videoUrls);  
      } catch (error) {  
        console.error('Error fetching media items:', error);  
      }  
    };  
  
    fetchMediaItems();  
  }, []);  
  
  const openImageViewer = (imageSrc: string) => {  
    setCurrentImageSrc(imageSrc);  
    setIsImageViewerOpen(true);  
  };  
  
  const closeImageViewer = () => {  
    setIsImageViewerOpen(false);  
  };  
  
  const openVideoModal = (index: number) => {  
    setCurrentVideoUrl(mediaItems[index].original);  
    setIsVideoModalOpen(true);  
  };  
  
  const closeVideoModal = () => {  
    setIsVideoModalOpen(false);  
  };  
  
  return (  
    <Container>  
      <GalleryContainer>  
        {mediaItems.map((item, index) => (  
          <GalleryItem  
            key={index}  
            onClick={() =>  
              item.isVideo  
                ? openVideoModal(index)  
                : openImageViewer(item.original)  
            }  
          >  
            {item.isVideo ? (  
              <>  
                <ReactPlayer url={item.thumbnail} width="100%" height="auto" />  
                <VideoIconOverlay>  
                  <PlayCircleFilledOutlinedIcon />  
                </VideoIconOverlay>  
              </>  
            ) : (  
              <GalleryImage src={item.thumbnail} alt={`Uploaded ${index}`} />  
            )}  
          </GalleryItem>  
        ))}  
      </GalleryContainer>  
      <ImageViewer  
        isOpen={isImageViewerOpen}  
        onRequestClose={closeImageViewer}  
        imageSrc={currentImageSrc}  
      />  
      <ImageModal  
        isOpen={isImageModalOpen}  
        onRequestClose={() => setIsImageModalOpen(false)}  
        mediaItems={mediaItems}  
        startIndex={currentMediaIndex}  
      />  
      <VideoModal  
        isOpen={isVideoModalOpen}  
        onRequestClose={closeVideoModal}  
        videoUrl={currentVideoUrl}  
      />  
    </Container>  
  );  
};  
  
export default VideoGallery;  
