import React from 'react';  
import Admin from '../../component/Admin/Admin';  
// import Footer from '../../component/Footer/Footer'; 

const AdminPage = () => {  
  return (  
    <div>  
      <Admin />  
      {/* <Footer />   */}
    </div>  
  );  
};  
  
export default AdminPage;  
