import React from 'react';  
import { Routes, Route } from 'react-router-dom';  
import { UploadPage, PhotoGalleryPage, VideoGalleryPage, LoginPage, AdminPage } from './page/page-routes';  
import PrivateRoute from './component/AuthContext/PrivateRoutes';  
  
export const Rroutes = () => {  
  return (  
    <Routes>  
      <Route path='*' element={<UploadPage />} />  
      <Route path='/' element={<UploadPage />} />  
      <Route path='/gallery' element={<PhotoGalleryPage />} />  
      <Route path='/videos' element={<VideoGalleryPage />} />  
      <Route path='/login' element={<LoginPage />} />  
      <Route element={<PrivateRoute />}>  
        <Route path='/admin' element={<AdminPage />} />  
      </Route>  
    </Routes>  
  );  
};  
  
export default Rroutes;  
