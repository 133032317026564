import { styled } from '@mui/system';  
import { Box, Card, TextField } from '@mui/material';  
  
export const Container = styled(Box)(({ theme }) => ({  
  display: 'flex',  
  alignItems: 'center',  
  justifyContent: 'center',  
  minHeight: '100vh',  
  padding: theme.spacing(2),  
}));  
  
export const StyledCard = styled(Card)(({ theme }) => ({  
  maxWidth: 400,  
  width: '100%',  
  padding: theme.spacing(2),  
}));  
  
export const StyledTextField = styled(TextField)(({ theme }) => ({  
  marginBottom: theme.spacing(2),  
}));  
  
export const Logo = styled('img')(({ theme }) => ({  
  width: '100px',  
  marginBottom: theme.spacing(2),  
}));  
