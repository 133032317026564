import styled from 'styled-components';  
  
export const AppContainer = styled.div`  
  display: flex;  
  flex-direction: column;  
  min-height: 100vh;  
`;  
  
export const ContentWrapper = styled.main`  
  flex: 1;  
`;  
