import { CSSProperties } from 'react';  
  
export const customStyles = {  
  content: {  
    top: '50%',  
    left: '50%',  
    right: 'auto',  
    bottom: 'auto',  
    marginRight: '-50%',  
    transform: 'translate(-50%, -50%)',  
    padding: '0',  
    border: 'none',  
    background: 'none',  
    overflow: 'hidden',  
  } as CSSProperties,  
  overlay: {  
    backgroundColor: 'rgba(0, 0, 0, 0.75)',  
    zIndex: 1300,  
  } as CSSProperties,  
};  
