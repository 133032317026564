import { IconButton } from '@mui/material';  
import styled from 'styled-components';  
  
export const UploadContainer = styled.div`  
  display: flex;  
  flex-direction: column;  
  align-items: center;  
  justify-content: center; /* Center vertically */  
  padding: 2rem;  
  margin-top: 100px; /* Adjust this value as needed */  
  width: 100%;  
  max-width: 600px; /* Add a maximum width for better centering */  
  margin-left: auto;  
  margin-right: auto;  
  height: calc(100vh - 100px); /* Adjust the height to center vertically */  
  
  @media (max-width: 768px) {  
    padding: 1rem;  
    margin-top: 50px; /* Adjust this value as needed */  
    height: calc(100vh - 50px); /* Adjust the height to center vertically */  
  }  
  
  @media (max-width: 480px) {  
    padding: 0rem;  
    margin-top: 120px; /* Adjust this value as needed */  
    height: calc(100vh - 200px); /* Adjust the height to center vertically */  
  }  
`;  
  
export const UploadButton = styled.button`  
  background: transparent;  
  font-family: "Baskerville";  
  border: 1px white solid;  
  color: #ffffff;  
  font-size: 0.95rem;  
`;  
  
export const PreviewContainer = styled.div`  
  display: flex;  
  flex-wrap: wrap;  
  justify-content: center;  
  margin-top: 1rem;  
  margin-bottom: 1rem; /* Add margin to separate from the buttons */  
  max-height: 300px; /* Set a maximum height for the container */  
  overflow-y: auto; /* Enable vertical scrolling if content exceeds max-height */  
  width: 100%;  
`;  
  
export const PreviewImageWrapper = styled.div`  
  position: relative;  
  margin: 1px;  
`;  
  
export const PreviewImage = styled.img`  
  width: 100px;  
  height: 100px;  
  object-fit: cover;  
  border: 2px solid #ddd;  
  border-radius: 5px;  
  
  @media (max-width: 480px) {  
    width: 50px;  
    height: auto;  
  }  
`;  
  
export const CustomIconButton = styled(IconButton)`  
  position: absolute;  
  bottom: 10px;  
  right: 10px;  
`;  
  
export const DeleteButton = styled.button`  
  position: absolute;  
  top: 0px;  
  right: 0px;  
  border: none;  
  color: white;  
  font-size: 30px;  
  border-radius: 50%;  
  cursor: pointer;  
`;  
  
export const GuestNameInput = styled.input`  
  display: block;  
  margin: 0.5rem auto;  
  padding: 1rem;  
  font-size: 1rem;  
  font-family: "Baskerville";  
  border: 1px solid #ddd;  
  border-radius: 3px;  
  max-width: 400px; /* Add a maximum width for better centering */  
`;  
  
export const ProgressBarContainer = styled.div`  
  width: 100%;  
  margin-top: 0.5rem;  
`;  
  
interface ProgressBarProps {  
  progress: number;  
}  
  
export const ProgressBar = styled.div<ProgressBarProps>`  
  width: ${({ progress }) => progress}%;  
  height: 5px;  
  background-color: #4caf50;  
  text-align: center;  
  color: white;  
  border-radius: 5px;  
  transition: width 0.2s;  
`;  
  
export const ThankYouMessage = styled.p`  
  font-family: "Baskerville";  
  font-size: 1.05rem;  
  color: #ffffff;  
  text-align: center;  
  margin-bottom: 2.5rem;  
  width: calc(100% - 2rem); /* Make text responsive */  
  max-width: 600px; /* Add a maximum width for better centering */  
`;  
