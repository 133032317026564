import React from 'react';  
import Rroutes from './routes';  
import Navbar from './component/NavBar/NavBar';  
import Footer from './component/Footer/Footer';  
import { AuthProvider } from './component/AuthContext/AuthContext';  
import { AppContainer, ContentWrapper } from './App.styles';  
  
const App: React.FC = () => {  
  return (  
    <AppContainer>  
      <AuthProvider>  
        <Navbar />  
        <ContentWrapper>  
          <Rroutes />  
        </ContentWrapper>  
        <Footer />  
      </AuthProvider>  
    </AppContainer>  
  );  
};  
  
export default App;  
