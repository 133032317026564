export const customStyles = {  
    content: {  
      top: '50%',  
      left: '50%',  
      right: 'auto',  
      bottom: 'auto',  
      marginRight: '-50%',  
      transform: 'translate(-50%, -50%)',  
      width: '80%',  
      height: '80%',  
      backgroundColor: 'black',  
      border: 'none',  
      borderRadius: '10px',  
    },  
    overlay: {  
      backgroundColor: 'rgba(0, 0, 0, 0.75)',  
      zIndex: 1300,  
    },  
  };  
  