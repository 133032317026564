import React from 'react';  
import Login from '../../component/Login/Login';  


const LoginPage = () => {  
  return (  
    <div>  
      <Login />  
    </div>  
  );  
};  
  
export default LoginPage;  
