import React, { useState, useEffect, useCallback } from 'react';  
import { IconButton, Typography, Box } from '@mui/material';  
import { Link } from 'react-router-dom';  
import logo from '../../assets/logo.png';
import gallerybutton from '../../assets/gallery-button.png';  
import { CustomAppBar, CustomToolbar, Logo } from './NavBar.styles';  
  
const Navbar: React.FC = () => {  
  const [showNavbar, setShowNavbar] = useState(true);  
  const [lastScrollY, setLastScrollY] = useState(0);  
  
  const controlNavbar = useCallback(() => {  
    if (window.scrollY === 0 || window.scrollY < 0) {  
      // Always show the navbar when the scroll position is at the top  
      setShowNavbar(true);  
    } else if (window.scrollY > lastScrollY) {  
      // If scrolling down, hide the navbar  
      setShowNavbar(false);  
    } else {  
      // If scrolling up, show the navbar  
      setShowNavbar(true);  
    }  
    setLastScrollY(window.scrollY);  
  }, [lastScrollY]);  
  
  useEffect(() => {  
    window.addEventListener('scroll', controlNavbar);  
  
    // Cleanup function  
    return () => {  
      window.removeEventListener('scroll', controlNavbar);  
    };  
  }, [controlNavbar]);  
  
  return (  
    <CustomAppBar position="fixed" style={{ top: showNavbar ? '0' : '-120px' }}> {/* Adjust the -120px value based on your navbar height */}  
      <CustomToolbar>  
        <Link to="/">  
          <Logo src={logo} alt="Syrus Wedding" />  
        </Link>  
        <Box sx={{ flexGrow: 1 }} />  
        <IconButton component={Link} to="/gallery" color="inherit" sx={{ fontSize: '2rem', p: 2 }}>  
        <img src={gallerybutton} alt="Gallery Icon" style={{ width: '2.8rem', height: '2.8rem', marginRight: '0rem' }} />
          <Typography variant="body1"></Typography>  
        </IconButton>  
      </CustomToolbar>  
    </CustomAppBar>  
  );  
};  
  
export default Navbar;  
