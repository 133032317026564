import React from 'react';  
import Modal from 'react-modal';  
import ImageViewer from 'react-simple-image-viewer';  
import { customStyles } from './ImageModal.styles';  
  
interface MediaItem {  
  original: string;  
  thumbnail: string;  
}  
  
interface ImageModalProps {  
  isOpen: boolean;  
  onRequestClose: () => void;  
  mediaItems: MediaItem[];  
  startIndex: number;  
}  
  
const ImageModal: React.FC<ImageModalProps> = ({  
  isOpen,  
  onRequestClose,  
  mediaItems,  
  startIndex,  
}) => {  
  const [currentIndex, setCurrentIndex] = React.useState(startIndex);  
  
  React.useEffect(() => {  
    setCurrentIndex(startIndex);  
  }, [startIndex]);  
  
  const images = mediaItems.map((item) => item.original);  
  
  
  const closeViewer = () => {  
    onRequestClose();  
  };  
  
  return (  
    <Modal  
      isOpen={isOpen}  
      onRequestClose={closeViewer}  
      style={customStyles}  
      contentLabel="Media Modal"  
      ariaHideApp={false}  
    >  
      {isOpen && images.length > 0 && (  
        <ImageViewer  
          src={images}  
          currentIndex={currentIndex}  
          onClose={closeViewer}  
          backgroundStyle={{  
            backgroundColor: "rgba(0,0,0,0.9)",  
          }}  
          disableScroll={true}  
        />  
      )}  
    </Modal>  
  );  
};  
  
export default ImageModal;  
