import React from 'react';  
import VideoGallery from '../../component/Gallery/VideoGallery';  

const VideoGalleryPage = () => {  
  return (  
    <div>  
      <VideoGallery />  
    </div>  
  );  
};  
  
export default VideoGalleryPage;  
