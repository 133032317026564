import React from 'react';  
import PhotoGallery from '../../component/Gallery/PhotoGallery';  

const PhotoGalleryPage = () => {  
  return (  
    <div>  
      <PhotoGallery />  
    </div>  
  );  
};  
  
export default PhotoGalleryPage;  
