import React, { useEffect, useState } from 'react';  
import AWS from 'aws-sdk';  
import { s3ReadConfig } from '../../s3-read-config';  
import ImageModal from '../ImageModal/ImageModal';  
import ImageViewer from '../ImageViewer/ImageViewer';  
import {  
  Container,  
  GalleryRow,  
  GalleryColumn,  
  GalleryItem,  
  GalleryImage,  
} from './Gallery.styles';  
  
interface MediaItem {  
  original: string;  
  thumbnail: string;  
  key: string;  
  isVideo: boolean;  
}  
  
const PhotoGallery: React.FC = () => {  
  const [mediaItems, setMediaItems] = useState<MediaItem[]>([]);  
  const [isImageModalOpen, setIsImageModalOpen] = useState(false);  
  const currentMediaIndex = 0;  
  const [isImageViewerOpen, setIsImageViewerOpen] = useState(false);  
  const [currentImageSrc, setCurrentImageSrc] = useState('');  
  
  useEffect(() => {  
    const fetchMediaItems = async () => {  
      const s3 = new AWS.S3({  
        region: s3ReadConfig.region,  
        accessKeyId: s3ReadConfig.accessKeyId,  
        secretAccessKey: s3ReadConfig.secretAccessKey,  
        endpoint: s3ReadConfig.endpoint,  
        s3ForcePathStyle: true,  
      });  
  
      const params = {  
        Bucket: s3ReadConfig.bucketName,  
      };  
  
      try {  
        console.log('Fetching media items...');  
        const data = await s3.listObjectsV2(params).promise();  
        console.log('Data fetched:', data);  
        const mediaUrls =  
          data.Contents?.map((item) => {  
            const url = s3.getSignedUrl('getObject', {  
              Bucket: s3ReadConfig.bucketName,  
              Key: item.Key,  
            });  
            return {  
              original: url,  
              thumbnail: url,  
              key: item.Key || '',  
              isVideo:  
                item.Key?.toLowerCase().endsWith('.mp4') ||  
                item.Key?.toLowerCase().endsWith('.webm') ||  
                item.Key?.toLowerCase().endsWith('.mov') ||  
                false,  
            };  
          }) || [];  
        console.log('Media URLs:', mediaUrls);  
        // Filter to only include images  
        const imageUrls = mediaUrls.filter(item => !item.isVideo);  
        console.log('Filtered image URLs:', imageUrls);  
        setMediaItems(imageUrls);  
      } catch (error) {  
        console.error('Error fetching media items:', error);  
      }  
    };  
  
    fetchMediaItems();  
  }, []);  
  
  useEffect(() => {  
    console.log('Media items updated:', mediaItems);  
  }, [mediaItems]);  
  
  const openImageViewer = (imageSrc: string) => {  
    setCurrentImageSrc(imageSrc);  
    setIsImageViewerOpen(true);  
  };  
  
  const closeImageViewer = () => {  
    setIsImageViewerOpen(false);  
  };  
  
  const renderColumns = () => {  
    const columnsCount = 4; // Adjust the number of columns as needed  
    const columns: JSX.Element[][] = Array.from({ length: columnsCount }, () => []);  
  
    mediaItems.forEach((item, index) => {  
      const columnIndex = index % columnsCount;  
      columns[columnIndex].push(  
        <GalleryItem  
          key={item.key}  
          onClick={() => openImageViewer(item.original)}  
        >  
          <GalleryImage src={item.thumbnail} alt={`Uploaded ${index}`} />  
        </GalleryItem>  
      );  
    });  
  
    return columns.map((columnItems, columnIndex) => (  
      <GalleryColumn key={columnIndex}>  
        {columnItems}  
      </GalleryColumn>  
    ));  
  };  
  
  return (  
    <Container>  
      <GalleryRow>{renderColumns()}</GalleryRow>  
      <ImageViewer  
        isOpen={isImageViewerOpen}  
        onRequestClose={closeImageViewer}  
        imageSrc={currentImageSrc}  
      />  
      <ImageModal  
        isOpen={isImageModalOpen}  
        onRequestClose={() => setIsImageModalOpen(false)}  
        mediaItems={mediaItems}  
        startIndex={currentMediaIndex}  
      />  
    </Container>  
  );  
};  
  
export default PhotoGallery;  
