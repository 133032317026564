import React, { useEffect } from 'react';  
import Modal from 'react-modal';  
import SimpleImageViewer from 'react-simple-image-viewer';  
  
interface ImageViewerProps {  
  isOpen: boolean;  
  onRequestClose: () => void;  
  imageSrc: string;  
}  
  
const ImageViewer: React.FC<ImageViewerProps> = ({  
  isOpen,  
  onRequestClose,  
  imageSrc,  
}) => {  
  useEffect(() => {  
    if (isOpen) {  
      document.body.style.overflow = 'hidden';  
    } else {  
      document.body.style.overflow = 'auto';  
    }  
  
    // Cleanup function to reset the overflow style when the component unmounts  
    return () => {  
      document.body.style.overflow = 'auto';  
    };  
  }, [isOpen]);  
  
  const closeViewer = () => {  
    onRequestClose();  
  };  
  
  return (  
    <Modal  
      isOpen={isOpen}  
      onRequestClose={closeViewer}  
      style={{  
        content: {  
          top: '50%',  
          left: '50%',  
        //   right: 'auto',  
        //   bottom: 'auto',  
          marginRight: '-50%',  
        //   transform: 'translate(-50%, -50%)',  
          padding: '0',  
          border: 'none',  
          background: 'none',  
          overflow: 'hidden',  
        },  
        overlay: {  
          backgroundColor: 'rgba(0, 0, 0, 0.75)',  
          zIndex: 1000, // Ensure the overlay is on top of other elements  
          pointerEvents: 'auto', // Ensure the overlay captures all pointer events  
        },  
      }}  
      contentLabel="Image Viewer Modal"  
      ariaHideApp={false}  
    >  
      {isOpen && (  
        <SimpleImageViewer  
          src={[imageSrc]}  
          currentIndex={0}  
          onClose={closeViewer}  
          backgroundStyle={{  
            backgroundColor: "rgba(0,0,0,0.9)",  
          }}  
          disableScroll={true}  
        />  
      )}  
    </Modal>  
  );  
};  
  
export default ImageViewer;  
