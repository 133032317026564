import React, { useState } from 'react';  
import { useNavigate } from 'react-router-dom';  
import { useAuth } from '../AuthContext/AuthContext';  
import { Button, Box, CardContent, CardActions, Typography } from '@mui/material';  
import { Container, StyledCard, StyledTextField, Logo } from './Login.styles';  
import logo from '../../assets/logo.png';  
  
const LoginPage: React.FC = () => {  
  const [email, setEmail] = useState('');  
  const [password, setPassword] = useState('');  
  const { login } = useAuth();  
  const navigate = useNavigate();  
  
  const handleLogin = () => {  
    // Perform your authentication logic here, e.g., call an API  
    const user = { email }; // Replace this with actual user data  
    login(user);  
    navigate('/admin'); // Redirect to the admin page after login  
  };  
  
  return (  
    <Container>  
      <StyledCard>  
        <CardContent>  
          <Box display="flex" justifyContent="center">  
            <Logo src={logo} alt="Logo" />  
          </Box>  
          <Typography variant="h5" component="div" gutterBottom>  
            Login  
          </Typography>  
          <StyledTextField  
            label="Email"  
            value={email}  
            onChange={(e) => setEmail(e.target.value)}  
            fullWidth  
            variant="outlined"  
          />  
          <StyledTextField  
            label="Password"  
            type="password"  
            value={password}  
            onChange={(e) => setPassword(e.target.value)}  
            fullWidth  
            variant="outlined"  
          />  
        </CardContent>  
        <CardActions>  
          <Button onClick={handleLogin} variant="contained" color="primary" fullWidth>  
            Log In  
          </Button>  
        </CardActions>  
      </StyledCard>  
    </Container>  
  );  
};  
  
export default LoginPage;  
