import React from 'react';  
// import { Container, FooterContainer, FooterLink, FooterText, CustomSocialIcon } from './Footer.styles';  
// import { Container, FooterContainer, FooterText, CustomSocialIcon } from './Footer.styles';  
import { Container, FooterContainer, FooterText } from './Footer.styles';  


const Footer: React.FC = () => {  
  return (  
    <FooterContainer>  
      <Container>  
        <FooterText>&copy; {new Date().getFullYear()} MoonsteriousHK. All Rights Reserved.</FooterText>  
        {/* <div>
        <CustomSocialIcon url="https://www.instagram.com" bgColor="#5D4954" fgColor='white' />
        <CustomSocialIcon url="https://www.facebook.com" bgColor="#5D4954" fgColor='white' />
        <CustomSocialIcon url="https://www.tiktok.com" bgColor="#5D4954" fgColor='white' />
        <CustomSocialIcon url="https://www.vimeo.com" bgColor="#5D4954" fgColor='white' />
        </div> */}
      </Container>  
    </FooterContainer>  
  );  
};  
  
export default Footer;  
