import styled from 'styled-components';  
  
export const Container = styled.div`  
  padding: 1rem;  
  margin-top: 120px;
`;  
  
export const ToggleButton = styled.button`  
  background: #ffd5d5;  
  font-family: 'Great Vibes';  
  border: none;  
  padding: 1rem 2rem;  
  color: white;  
  cursor: pointer;  
  font-size: 1rem;  
  margin-bottom: 1rem;  
  
  @media (max-width: 768px) {  
    padding: 0.75rem 1.5rem;  
    font-size: 0.875rem;  
  }  
  
  @media (max-width: 480px) {  
    padding: 0.5rem 1rem;  
    font-size: 0.75rem;  
  }  
`;  
  
export const ButtonContainer = styled.div`  
  display: flex;  
  gap: 1rem;  
  margin: 1rem;  
  flex-wrap: wrap; /* Allow buttons to wrap to the next line if needed */  
  @media (max-width: 768px) {  
    gap: 0.75rem;  
    margin: 0.75rem;  
  }  
  
  @media (max-width: 480px) {  
    gap: 0.5rem;  
    margin: 0.5rem;  
  }  
`;  
  
export const Button = styled.button`  
  background: #ffd5d5;  
  font-family: 'Great Vibes';  
  border: none;  
  padding: 1rem 2rem;  
  color: white;  
  cursor: pointer;  
  font-size: 1rem;  
  
  @media (max-width: 768px) {  
    padding: 0.75rem 1.5rem;  
    font-size: 0.875rem;  
  }  
  
  @media (max-width: 480px) {  
    padding: 0.5rem 1rem;  
    font-size: 0.75rem;  
  }  
`;  
  
export const SelectedCount = styled.div`  
  font-size: 1rem;  
  font-family: 'Great Vibes';  
  margin-bottom: 0.5rem;  
  color: #333;  
  
  @media (max-width: 768px) {  
    font-size: 0.875rem;  
    margin-bottom: 0.375rem;  
  }  
  
  @media (max-width: 480px) {  
    font-size: 0.75rem;  
    margin-bottom: 0.25rem;  
  }  
`;  
  
export const TableContainer = styled.div`  
  width: 100%;  
  position: relative;  
`;  
  
export const Table = styled.table`  
  width: 100%;  
  border-collapse: collapse;  
`;  
  
export const TableHead = styled.thead`  
  background-color: #f8f8f8;  
`;  
  
export const TableRow = styled.tr`  
  &:nth-child(even) {  
    background-color: #f2f2f2;  
  }  
`;  
  
export const TableHeader = styled.th`  
  padding: 0.5rem;  
  border: 1px solid #ddd;  
  text-align: left;  
  color: #333;  
`;  
  
export const TableCell = styled.td`  
  padding: 0.5rem;  
  border: 1px solid #ddd;  
  color: #333;  
  position: relative;  
`;  
  
export const Checkbox = styled.input`  
  transform: scale(1.5);  
`;  
  
export const Image = styled.img`  
  width: 50px;  
  height: 50px;  
  object-fit: cover;  
  border-radius: 5px;  
`;  
  
export const GalleryContainer = styled.div`  
  display: grid;  
  grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));  
  gap: 16px;  
  
  @media (max-width: 768px) {  
    grid-template-columns: repeat(3, 1fr);  
  }  
  
  @media (max-width: 480px) {  
    grid-template-columns: repeat(2, 1fr);  
  }  
`;  
  
export const GalleryItem = styled.div`  
  position: relative;  
  cursor: pointer;  
  border: 5px solid white;  
  border-radius: 5px;  
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);  
  overflow: hidden;  
  display: flex;  
  justify-content: center;  
  align-items: center;  
  transition: transform 0.3s ease;  
  
  &:hover {  
    transform: scale(1.05);  
  }  
`;  
  
export const GalleryImage = styled.img`  
  width: 100%;  
  height: auto;  
  object-fit: cover;  
  border-radius: 5px;  
`;  
  
export const GalleryCheckbox = styled.input`  
  position: absolute;  
  top: 8px;  
  left: 8px;  
  transform: scale(1.5);  
  background: rgba(255, 255, 255, 0.8);  
  border-radius: 3px;  
  z-index: 2; /* Ensure checkbox is above other elements */  
`;  
  
export const VideoIconOverlay = styled.div`  
  position: absolute;  
  top: 50%;  
  left: 50%;  
  transform: translate(-50%, -50%);  
  color: white;  
  font-size: 2rem;  
  z-index: 1; /* Ensure icon is above other elements */  
`;  
