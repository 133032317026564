import styled from 'styled-components';  
  
export const Container = styled.div`  
  width: 100%;  
  padding: 1rem;  
  margin-top: 120px; /* Adjust this value as needed */  
  box-sizing: border-box; /* Ensure padding is included in the width calculation */  
  
  /* Pseudo-element for background image */  
  &::before {  
    content: '';  
    position: fixed ;  
    top: 0;  
    left: 0;  
    right: 0;  
    bottom: 0;  
    background: url('src/assets/logo.png') no-repeat center center;  
    background-size: cover;  
    opacity: 0.5; /* Adjust opacity as needed */  
    z-index: -1; /* Ensure background is behind content */  
  }  
  
  @media (max-width: 768px) {  
    padding-top: 50px; /* Adjust padding for smaller screens */  
    &::before {  
      background-size: contain; /* Adjust background size for smaller screens */  
    }  
  }  
  
  @media (max-width: 480px) {  
    padding-top: 40px; /* Adjust padding for even smaller screens */  
    &::before {  
      background-size: cover; /* Adjust background size for smallest screens */  
    }  
  }  
`;  
  
export const GalleryRow = styled.div`  
  display: flex;  
  flex-wrap: wrap;  
  margin: -4px; /* Adjust margin to counteract padding in columns */  
`;  
  
export const GalleryColumn = styled.div`  
  flex: 25%;  
  max-width: 25%;  
  padding: 4px;  
  box-sizing: border-box; /* Ensure padding is included in the width calculation */  
  
  & > div {  
    margin-bottom: 8px;  
  }  
  
  @media screen and (max-width: 768px) {  
    flex: 50%;  
    max-width: 50%;  
  }  
  
  @media screen and (max-width: 480px) {  
    flex: 50%;  
    max-width: 50%;  
  }  
`;  
  
export const GalleryItem = styled.div`  
  position: relative;  
  cursor: pointer;  
  border: 0px solid white;  
  border-radius: 8px;  
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);  
  overflow: hidden;  
  display: flex;  
  justify-content: center;  
  align-items: center;  
  transition: transform 0.3s ease;  
  
  &:hover {  
    transform: scale(1.05);  
  }  
`;  
  
export const GalleryImage = styled.img`  
  width: 100%;  
  height: auto;  
  object-fit: cover;  
  border-radius: 8px;  
`;  
