import React, { useEffect, useState } from 'react';  
import AWS from 'aws-sdk';  
import JSZip from 'jszip';  
import { saveAs } from 'file-saver';  
import { s3ReadConfig } from '../../s3-read-config';  
import ImageModal from '../ImageModal/ImageModal';  
import VideoModal from '../VideoModal/VideoModal';  
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';  
import { faVideo } from '@fortawesome/free-solid-svg-icons';  
import ReactPlayer from 'react-player';  
import {  
  Container,  
  ToggleButton,  
  ButtonContainer,  
  Button,  
  SelectedCount,  
  TableContainer,  
  Table,  
  TableHead,  
  TableRow,  
  TableHeader,  
  TableCell,  
  Checkbox,  
  Image,  
  GalleryContainer,  
  GalleryItem,  
  GalleryImage,  
  GalleryCheckbox,  
  VideoIconOverlay  
} from './Admin.styles';  
  
interface MediaItem {  
  original: string;  
  thumbnail: string;  
  key: string;  
  isVideo: boolean;  
}  
  
const Admin: React.FC = () => {  
  const [mediaItems, setMediaItems] = useState<MediaItem[]>([]);  
  const [selectedMediaItems, setSelectedMediaItems] = useState<number[]>([]);  
  const [isTableView, setIsTableView] = useState(false); // Set to false for Gallery view as default  
  const [isModalOpen, setIsModalOpen] = useState(false);  
  const [currentMediaIndex, setCurrentMediaIndex] = useState(0);  
  const [isVideoModalOpen, setIsVideoModalOpen] = useState(false);  
  const [currentVideoUrl, setCurrentVideoUrl] = useState('');  
  
  useEffect(() => {  
    const fetchMediaItems = async () => {  
      const s3 = new AWS.S3({  
        region: s3ReadConfig.region,  
        accessKeyId: s3ReadConfig.accessKeyId,  
        secretAccessKey: s3ReadConfig.secretAccessKey,  
        endpoint: s3ReadConfig.endpoint,  
        s3ForcePathStyle: true, // Necessary for custom endpoints  
      });  
  
      const params = {  
        Bucket: s3ReadConfig.bucketName,  
      };  
  
      try {  
        const data = await s3.listObjectsV2(params).promise();  
        const mediaUrls = data.Contents?.map((item) => {  
          const url = s3.getSignedUrl('getObject', {  
            Bucket: s3ReadConfig.bucketName,  
            Key: item.Key,  
          });  
          return {  
            original: url,  
            thumbnail: url,  
            key: item.Key || '',  
            isVideo: item.Key?.toLowerCase().endsWith('.mp4') ||   
                     item.Key?.toLowerCase().endsWith('.webm') ||   
                     item.Key?.toLowerCase().endsWith('.mov') ||   
                     false, // Ensure isVideo is always boolean  
          };  
        }) || [];  
        setMediaItems(mediaUrls);  
      } catch (error) {  
        console.error('Error fetching media items:', error);  
      }  
    };  
  
    fetchMediaItems();  
  }, []);  
  
  const handleCheckboxChange = (index: number) => {  
    const selected = [...selectedMediaItems];  
    if (selected.includes(index)) {  
      const newSelected = selected.filter((item) => item !== index);  
      setSelectedMediaItems(newSelected);  
    } else {  
      selected.push(index);  
      setSelectedMediaItems(selected);  
    }  
  };  
  
  const downloadSelectedMediaItems = async () => {  
    const zip = new JSZip();  
    const folder = zip.folder("media");  
  
    const downloadPromises = selectedMediaItems.map((index) => {  
      return fetch(mediaItems[index].original)  
        .then((response) => response.blob())  
        .then((blob) => {  
          folder?.file(mediaItems[index].key, blob);  
        });  
    });  
  
    await Promise.all(downloadPromises);  
  
    zip.generateAsync({ type: 'blob' }).then((content) => {  
      saveAs(content, 'media.zip');  
    });  
  };  
  
  const selectAllMediaItems = () => {  
    const allIndexes = mediaItems.map((_, index) => index);  
    setSelectedMediaItems(allIndexes);  
  };  
  
  const clearAllSelectedMediaItems = () => {  
    setSelectedMediaItems([]);  
  };  
  
  const toggleView = () => {  
    setIsTableView(!isTableView);  
  };  
  
  const openModal = (index: number) => {  
    if (mediaItems[index].isVideo) {  
      setCurrentVideoUrl(mediaItems[index].original);  
      setIsVideoModalOpen(true);  
    } else {  
      setCurrentMediaIndex(index);  
      setIsModalOpen(true);  
    }  
  };  
  
  const closeModal = () => {  
    setIsModalOpen(false);  
  };  
  
  const closeVideoModal = () => {  
    setIsVideoModalOpen(false);  
  };  
  
  return (  
    <Container>  
      <ToggleButton onClick={toggleView}>  
        {isTableView ? 'Switch to Gallery View (Admin)' : 'Switch to Table View (Admin)'}  
      </ToggleButton>  
      <div>  
        <SelectedCount>  
          {selectedMediaItems.length} media item(s) selected  
        </SelectedCount>  
      </div>
      <ButtonContainer>  
        <Button onClick={selectAllMediaItems}>Select All</Button> 
        <div>
        <Button onClick={clearAllSelectedMediaItems}>Clear Selection</Button>  
        </div>  
        {selectedMediaItems.length > 0 && (  
          <Button onClick={downloadSelectedMediaItems}>  
            Download Selected Media  
          </Button>  
        )}  
      </ButtonContainer>  
      {isTableView ? (  
        <TableContainer>  
          <Table>  
            <TableHead>  
              <TableRow>  
                <TableHeader></TableHeader>  
                <TableHeader>Media</TableHeader>  
                <TableHeader>Object Name</TableHeader>  
              </TableRow>  
            </TableHead>  
            <tbody>  
              {mediaItems.map((item, index) => (  
                <TableRow key={index}>  
                  <TableCell>  
                    <Checkbox  
                      type="checkbox"  
                      checked={selectedMediaItems.includes(index)}  
                      onChange={() => handleCheckboxChange(index)}  
                    />  
                  </TableCell>  
                  <TableCell>  
                    {item.isVideo ? (  
                      <>  
                        <ReactPlayer url={item.thumbnail} width='50px' height='50px' />  
                      </>  
                    ) : (  
                      <>  
                        <Image src={item.thumbnail} alt={`Image ${index}`} />  
                      </>  
                    )}  
                  </TableCell>  
                  <TableCell>{item.key}</TableCell>  
                </TableRow>  
              ))}  
            </tbody>  
          </Table>  
        </TableContainer>  
      ) : (  
        <GalleryContainer>  
          {mediaItems.map((item, index) => (  
            <GalleryItem key={index} onClick={() => openModal(index)}>  
              <GalleryCheckbox  
                type="checkbox"  
                checked={selectedMediaItems.includes(index)}  
                onClick={(e) => e.stopPropagation()} // Stop propagation to prevent opening modal  
                onChange={() => handleCheckboxChange(index)}  
              />  
              {item.isVideo ? (  
                <>  
                  <ReactPlayer url={item.thumbnail} width='100%' height='auto' />  
                  <VideoIconOverlay>  
                    <FontAwesomeIcon icon={faVideo} />  
                  </VideoIconOverlay>  
                </>  
              ) : (  
                <GalleryImage src={item.thumbnail} alt={`Uploaded ${index}`} />  
              )}  
            </GalleryItem>  
          ))}  
        </GalleryContainer>  
      )}  
      <ImageModal  
        isOpen={isModalOpen}  
        onRequestClose={closeModal}  
        mediaItems={mediaItems.filter(item => !item.isVideo)}  
        startIndex={currentMediaIndex}  
      />  
      <VideoModal  
        isOpen={isVideoModalOpen}  
        onRequestClose={closeVideoModal}  
        videoUrl={currentVideoUrl}  
      />  
    </Container>  
  );  
};  
  
export default Admin;  
